<template>
  <div>
    <div class="" ref="tabs">
      <div class="">
        <NoteForm
            :model_type="model"
            :model_id="parseInt(model_id)"
            :permissions="{
                      can_create:!!$store.getters['entities/user-permissions/find']('create notes')
                            }
                  "
        ></NoteForm>
      </div>
    </div>
    <div class="">
      <div class="">
        <div
            :key="note.id"
            v-for="note in notes"
        >
          <NoteListItem
              :model_type="model"
              :model_id="parseInt(model_id)"
              :permissions="{
                                            can_delete: !!$store.getters['entities/user-permissions/find']('delete notes') ,
                                            can_edit: !!$store.getters['entities/user-permissions/find']('edit notes'),
                                          }"
              :note="note"
          ></NoteListItem>
        </div>
        <b-pagination
            class="margin-top"
            :total="notes_count"
            :current.sync="page"
            :range-before="2"
            :range-after="2"
            :per-page="limit"
            aria-next-label="Next page"
            aria-previous-label="Previous page"
            aria-page-label="Page"
            aria-current-label="Current page"
        ></b-pagination>
      </div>

    </div>
  </div>

</template>

<script>
import NoteForm from "@/components/notes/NoteForm";
import NoteListItem from "@/components/notes/NoteListItem";
import Note from "@/models/Note";


export default {
  name: "NotesPanel",
  components: {
    NoteForm,
    NoteListItem,
  },
  data() {
    return {
      page: 1,
      limit: 20,
      create: false,
      loading: false,
      loaded: false,

    }
  },
  props: {
    model:{
      type:String,
      default() {
        return 'user'
      }
    },
    canEdit: {
      type: Boolean, default() {
        return false
      }
    },
    model_id:{
      type:Number,
      required:true
    },
  },

  computed: {
    notes_count() {
      return Note.query().count()
    },
    notes() {
      return Note.query()
          .with("user")
          .orderBy("created_at", "desc")
          .offset((this.page - 1) * this.limit)
          .limit(this.limit)
          .get();
    }
  },
  mounted() {
    this.loading = true

    Promise.all([Note.FetchAllByModel({page: 1, limit: 999}, {}, ['user'], {
      id: this.model_id,
      type: this.model
    })]).then(() => {
      this.loading = false
    }).catch(err => {
          this.handleError(err)

          this.loading = false
        }
    )
  }
}
</script>
