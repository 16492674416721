<template>
  <div class="listitem">
    <article class="media">
      <figure class="media-left">
        <p class="has-text-centered">
          <b-tooltip :label="type.name" position="is-top">
            <b-icon class="icon rounded is-large box is-64x64" :icon="type.icon"></b-icon>
          </b-tooltip>
        </p>
      </figure>
      <div class="box media-content is-relative">
        <div class="">
          <b-dropdown class="listitem-dropdown" :position="'is-top-left'" aria-role="list">
            <b-button type="is-primary" :icon-right="$tc('icons.more-vertical')" slot="trigger" class="mb-4">
            </b-button>

            <b-dropdown-item aria-role="listitem" @click="editNote(note)">Edit</b-dropdown-item>
            <b-dropdown-item aria-role="listitem" @click="startDelete(note)">Delete</b-dropdown-item>
          </b-dropdown>
          <p class="subtitle is-7">
            created by {{ note.user.first_name }} {{ note.user.last_name }} @
            {{ (new Date(note.created_at).toLocaleString()) }}
            <span
                v-if="note.created_at !== note.updated_at"
            >edited @ {{ (new Date(note.updated_at).toLocaleString()) }}</span>
          </p>

          <div v-if="!noteBeingEdited" class="ck-content" v-html="note.note"></div>
          <div v-if="noteBeingEdited">
            <b-field style="overflow: hidden">
              <ckeditor :editor="editor" v-model="note.note" :config="editorConfig"></ckeditor>
            </b-field>
            <b-field>
              <b-button @click="saveEdit" type="is-primary">Save</b-button>
            </b-field>
          </div>
        </div>
      </div>
    </article>

    <hr/>
  </div>
</template>

<script>
import Note from "@/models/Note";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import {editorConfig} from "@/editorConfig";

export default {
  name: "NoteListItem",
  props: {
    model_type: {type: String, required: true},
    model_id: {type: Number, required: true},
    permissions: {
      type: Object,
      default: () => ({
        can_delete: false,
        can_create: false,
        can_edit: false,
      }),
    },

    note: {
      type: Object,
      default: () => Object,
    },
  },
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {
        ...editorConfig,
        ...{placeholder: "Note body"},
      },
      noteBeingEdited: false,
      loadingNote: false,
    };
  },
  computed: {
    type() {
      return {name: "Note", icon: this.$tc('icons.note')};
    },
  },
  methods: {
    saveEdit() {
      this.loadingNote = true;

      Note.Update(this.note, {type: this.model_type, id: this.model_id}).then(() => {
        this.loadingNote = false;
        this.$buefy.snackbar.open({
          message: "Note edited",
          queue: false,
          type: "is-primary",
        });
      }).catch(
          err => {
            if (err.response.status === 422) {
              this.$store.dispatch("toast/createToast", {
                message: JSON.stringify(err.response.data),
              });
            } else {
              this.$store.dispatch("toast/createToast", {
                message: JSON.stringify(err),
              });
            }
          }
      );
      this.noteBeingEdited = false;
    },
    editNote(note) {
      if (
          this.permissions.can_edit ||
          note.user_id === this.$store.state.user.id
      ) {
        return (this.noteBeingEdited = true);
      }
      this.$store.dispatch("toast/createToast");
    },
    deleteNote(note) {
      this.$store.dispatch("loader/show");
      Note.Delete(note.id, {type: this.model_type, id: this.model_id}).then(() => {
        this.loadingNote = false;
        this.$buefy.snackbar.open({
          message: "Note deleted",
          queue: false,
          type: "is-danger",
        })
        this.$store.dispatch("loader/hide");
      }).catch(
          err => {
            if (err.response.status === 422) {
              this.$store.dispatch("toast/createToast", {
                message: JSON.stringify(err.response.data),
              });
            } else {
              this.$store.dispatch("toast/createToast", {
                message: JSON.stringify(err),
              });
            }
          }
      );
    },
    startDelete(note) {
      if (this.permissions.can_delete || note.user_id === this.$store.state.user.id
      ) {
        this.$buefy.dialog.confirm({
          title: "Deleting note",
          message:
              "Are you sure you want to <b>delete</b> this note? This action cannot be undone.",
          confirmText: "Delete Note",
          type: "is-danger",
          hasIcon: true,
          onConfirm: () => this.deleteNote(note),
        });
        return;
      }
      this.$store.dispatch("toast/createToast");
    },
  },
};
</script>
