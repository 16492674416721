<template>
  <form @submit.prevent="submit">
    <b-field style="overflow: hidden">
      <ckeditor
          style="overflow: hidden"
          :disabled="!permissions.can_create"
          :editor="editor"
          v-model="form.note"
          :config="editorConfig"
      ></ckeditor>
    </b-field>
    <b-field>
      <b-button
          :disabled="!permissions.can_create"
          @click="saveNote"
          class="margin-top"
          :loading="savingNote"
          type="is-primary"
      >Submit Note
      </b-button>
    </b-field>

    <b-loading
        :is-full-page="false"
        :active.sync="savingNote"
    ></b-loading>
  </form>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import {editorConfig} from "@/editorConfig";
import Note from "@/models/Note"


export default {
  name: "NoteForm",
  props: {
    model_type: {type: String, required: true},
    model_id: {type: Number, required: true},
    permissions: {
      type: Object,
      default: () => ({
        can_delete: false,
        can_create: false,
        can_edit: false,
      }),
    },
    action: {
      type: String,
      default: "create",
    },
  },
  mounted() {
  },
  data() {
    return {
      loaded: false,
      savingNote: false,
      editor: ClassicEditor,
      editorConfig: {
        ...editorConfig,
        ...{placeholder: "An optional description"},
      },
      form: {
        note: "",
      },

    };
  },
  computed: {},
  // watch: {
  //   activity() {
  //     this.form = this.activity;
  //   },
  // },
  methods: {
    submit() {
      this.saveNote()
    },
    saveNote() {
      this.savingNote = true;
      Note.Store(
          this.form,
          {type: this.model_type, id: this.model_id}
      ).then(() => {
        this.$buefy.snackbar.open('Note created!')
        this.savingNote = false;
        this.form = {note: ''};
      }).catch(
          err => {
            this.handleError(err)
            this.savingNote = false
          }
      );
    },
    // saveEdit() {
    //   ApplicationNote.update({
    //     where: this.noteBeingEdited.id,
    //     data: {note: this.noteBeingEdited.note},
    //   });
    //   ApplicationNote.updateRemote(this.noteBeingEdited);
    //   this.noteBeingEdited = Object;
    // },
    // editNote(note) {
    //   if (
    //       (note.user_id == this.$store.state.user.id ||
    //           this.administrate_notes == true) &&
    //       this.edit_notes
    //   ) {
    //     this.noteBeingEdited = note;
    //     return;
    //   }
    //   this.$store.dispatch("toast/createToast");
    // },
  },
};
</script>
