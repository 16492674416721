<template>
  <form @submit.prevent="submit">
    <div :class="{'modal-card':inModal}" style="width: auto;">
      <header v-if="inModal" class="modal-card-head">
        <p class="modal-card-title">{{ edit === false ? 'Log a new' : 'Edit' }} Incident</p>
      </header>
      <section :class="{'modal-card-body':inModal}">
        <b-field label="Incident Date">
          <b-datepicker  v-model="incident_date" expanded placeholder="Incident Date"
                        required>

          </b-datepicker>
        </b-field>
        <b-field label="Campus">
          <b-select v-model="incidentObject.campus_id" :loading="loadingCampuses" expanded placeholder="Campus"
                    required>
            <option v-for="campus in campuses" :key="campus.id" :value="campus.id">
              {{ campus.name }}
            </option>
          </b-select>
        </b-field>
        <b-field label="Users">
          <PerfectScrollbarWrapper>
          <b-table
              :bordered="true"
              class="is-fullwidth"
              :data="incidentObject.users"
              :hoverable="true"
              :striped="true"
          >
            <template #empty>
              <div class="has-text-centered">No users</div>
            </template>
            <b-table-column
                v-slot="props"
                label="User"
            >
              <UsersFilter :campus_id="incidentObject.campus_id"
                           :dropdown-direction="'bottom'"
                           :selected_user_id="props.row.user_id!==null?props.row.user_id:null"
                           :type="'autocomplete'"
                           @selected="option=>props.row.user_id = option.id"></UsersFilter>
            </b-table-column>

            <b-table-column
                v-slot="props"
                field="gets_task"
                label="Involvement"
            >
              <b-select v-model="props.row.involvement_id" expanded placeholder="Involvement"
                        required>
                <option v-for="involvement in $store.state.incidents.involvements" :key="involvement.id"
                        :value="involvement.id">
                  {{ involvement.name }}
                </option>
              </b-select>
            </b-table-column>
            <b-table-column
                v-slot="props"
                cell-class="py-1"
                centered
                custom-key="actions"
                label="Actions"
                narrowed
            >
              <b-field grouped>
                <b-field>
                  <b-button
                      icon-right="delete"
                      type="is-ghost"
                      class="has-text-danger"
                      @click.prevent="removeUser(props.row)"
                  />
                </b-field>
              </b-field>
            </b-table-column>
          </b-table>

          </PerfectScrollbarWrapper>
        </b-field>
        <b-field>
          <b-button type="is-primary" @click="addUser">
            Add User
          </b-button>
        </b-field>
        <b-field label="KPIs">
          <b-field v-for="(kpi,index) in kpis" :key="index" grouped>
            <b-button
                class="mr-2 has-text-danger"
                icon-right="delete"
                type="is-ghost"
                @click.prevent="removeKPI(kpi)"
            />
            <b-checkbox v-model="incidentObject.kpis[kpi[0]]" :false-value="false"
                        :true-value="true">
              {{ kpi[0] }}
            </b-checkbox>

          </b-field>
          <b-field grouped>
            <b-input v-model="newKPI" placeholder="KPI name"></b-input>
            <b-button type="is-primary" @click="addKPI">
              Add KPI
            </b-button>
          </b-field>

        </b-field>
        <b-field label="Charge">
          <b-select v-model="incidentObject.charge_id" expanded placeholder="Charge"
                    required>

            <optgroup v-for="chargeGroup in $store.state.incidents.charges" :key="chargeGroup.id"
                      :label="chargeGroup.name">
              <option v-for="charge in chargeGroup.items" :key="charge.id" :value="charge.id">
                {{ charge.name }}
              </option>
            </optgroup>
          </b-select>
        </b-field>
        <b-field label="Disciplinary Action">
          <b-select v-model="incidentObject.disciplinary_action_id" expanded placeholder="Disciplinary Action"
                    >
            <option v-for="action in $store.state.incidents.actions" :key="action.id" :value="action.id">
              {{ action.name }}
            </option>
          </b-select>
        </b-field>
        <b-field label="Category">
          <b-select v-model="incidentObject.category_id" expanded placeholder="Category"
                    required>
            <optgroup v-for="categoryGroup in $store.state.incidents.categories" :key="categoryGroup.id"
                      :label="categoryGroup.name">
              <option v-for="category in categoryGroup.items" :key="category.id" :value="category.id">
                {{ category.name }}
              </option>
            </optgroup>
          </b-select>
        </b-field>
        <b-field label="Status">
          <b-select v-model="incidentObject.status_id" expanded placeholder="Status"
                    required>
            <option v-for="status in $store.state.incidents.statuses" :key="status.id" :value="status.id">
              {{ status.name }}
            </option>
          </b-select>
        </b-field>
        <b-field v-if="(edit||created) && loaded"
                 NoteForm label="Notes"></b-field>
        <NotesPanel
            v-if="(edit||created) && loaded"
            :model="'incidents'"
            :model_id="parseInt(incidentObject.id)"
        ></NotesPanel>
        <MediaPanel
            v-if="(edit||created) && loaded"
            :canCreate="!!$store.getters['entities/user-permissions/find']('create media')"
            :canDelete="!!$store.getters['entities/user-permissions/find']('delete media')"
            :has-tag="false"
            :model="'incidents'"
            :model_id="parseInt(incidentObject.id)"
            :title="'Media'"
        ></MediaPanel>
      </section>

      <footer :class="{'modal-card-foot':inModal}">
        <b-field class="mt-3 has-text-centered" expanded grouped position="is-centered">
          <b-field position="is-centered">
            <b-button
                :disabled="(edit||created)?!canEdit:!canCreate"
                :icon-right="$tc(`icons.${edit===false?'create':'edit'}`)"
                native-type="submit"
                type="is-primary">{{ (edit || created) ? 'Save' : 'Submit' }}
            </b-button>
          </b-field>
          <b-field v-if="(edit||created)&&canDelete" position="is-centered">
            <b-button
                :icon-right="$tc(`icons.delete`)"
                type="is-danger"
                @click.prevent="startDelete()">Delete
            </b-button>
          </b-field>
        </b-field>
      </footer>
    </div>
    <b-loading v-model="loading" :can-cancel="false" :is-full-page="false"></b-loading>
  </form>
</template>

<script>

import {format} from "date-fns";
import UsersFilter from "@/components/panelled-dash/UsersFilter";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import {editorConfig} from "@/editorConfig";
import MediaPanel from "@/components/media/MediaPanel";
import Campus from "@/models/Campus";
import NotesPanel from "@/components/users/NotesPanel";
import Incident from "@/models/Incident";
import PerfectScrollbarWrapper from "@/components/scrollbar/PerfectScrollbarWrapper";

export default {
  name: "IncidentForm",
  components: {PerfectScrollbarWrapper, NotesPanel, UsersFilter, MediaPanel},
  data() {
    return {
      newKPI: null,
      loaded: false,
      loading: false,
      created: false,
      loadingClassGroups: false,
      loadingCampuses: false,

      editor: ClassicEditor,
      editorConfig: {
        ...editorConfig,
        ...{placeholder: "An optional note"},
      },
      incidentObject: {
        campus_id: null,
        charge_id: null,
        disciplinary_action_id: null,
        category_id: null,
        status_id: null,
        users: [],
        kpis: {},
        incident_date: null
      }
    }
  },

  computed: {
    campuses() {
      return Campus.query().get()
    },

    kpis() {
      return Object.entries(this.incidentObject.kpis)
    },
    incident_date: {
      get() {
        if (this.incidentObject.incident_date !== null) {
          return new Date(this.incidentObject.incident_date)
        }
        return null
      },
      set(newValue) {
        this.incidentObject.incident_date = format(newValue, 'yyyy-MM-dd')

      },
    },
  },

  methods: {
    startDelete() {
      this.$buefy.dialog.confirm({
        title: `Deleting Incident`,
        confirmText: `Delete Incident`,
        hasIcon: true,
        type: "is-danger",
        message: `Are you sure you want to delete this incident?`,
        onConfirm: () =>
            Incident.Delete(this.incidentObject.id)
                .then(() => {
                  this.$buefy.snackbar.open(`Incident deleted!`);
                  this.removeQuery(['incident_id'])

                  this.$emit('close')
                })
                .catch((err) => {
                  this.handleError(err)
                }),
      });
    }, removeUser(user) {
      this.$buefy.dialog.confirm({
        title: `Removing User`,
        confirmText: `Remove User`,
        hasIcon: true,
        type: "is-danger",
        message: `Are you sure you want to remove this user?`,
        onConfirm: () => {
          this.incidentObject.users = this.incidentObject.users.filter(item => item.user_id !== user.user_id)
        }
      });
    },
    removeKPI(kpi) {
      this.incidentObject.kpis = Object.fromEntries(this.kpis.filter(value => value[0] !== kpi[0]))
    },
    addKPI() {
      if (this.newKPI !== null) {
        let array = this.kpis
        array.push([this.newKPI, false])
        this.incidentObject.kpis = Object.fromEntries(array)
        this.newKPI = null
      }
    },
    addUser() {
      this.incidentObject.users.push({user_id: null, involvement_id: 1})
      this.$forceUpdate()
    },
    submit() {
      if (this.edit || this.created) {
        if (this.canEdit) {
          this.loading = true
          Incident.Update({
            id: this.incidentObject.id,
            campus_id: this.incidentObject.campus_id,
            charge_id: this.incidentObject.charge_id,
            disciplinary_action_id: this.incidentObject.disciplinary_action_id,
            category_id: this.incidentObject.category_id,
            status_id: this.incidentObject.status_id,
            users: this.incidentObject.users,
                kpis: this.incidentObject.kpis,
                incident_date: this.incidentObject.incident_date,
              }, true
          ).then(() => {
            this.loading = false
            this.$buefy.snackbar.open(`Incident updated!`)
            this.$emit('close')
          }).catch(err => {
            this.loading = false
            this.handleError(err)
          })
        } else {
          this.$store.dispatch('toast/createToast')
        }
      } else {
        if (this.canCreate) {
          this.loading = true
          Incident.Store({
            campus_id: this.incidentObject.campus_id,
            charge_id: this.incidentObject.charge_id,
            disciplinary_action_id: this.incidentObject.disciplinary_action_id,
            category_id: this.incidentObject.category_id,
            status_id: this.incidentObject.status_id,
            users: this.incidentObject.users,
            kpis: this.incidentObject.kpis,
            incident_date: this.incidentObject.incident_date,
          }).then(({entities: {incidents}}) => {
            this.loading = false
            this.$buefy.snackbar.open(`Incident submitted!`)
            this.incidentObject.id = incidents[0].id

            this.created = true
          }).catch(err => {
            this.loading = false
            this.handleError(err)
          })
        } else {
          this.$store.dispatch('toast/createToast')
        }
      }
    }
  }
  ,
  mounted() {
    this.loadingCampuses = true
    Campus.FetchAll({page: 1, limit: 99}).then(() => {
      this.loadingCampuses = false
    })
    if (this.edit) {

      this.incidentObject = JSON.parse(JSON.stringify(this.incident))
      this.loaded = true

    } else {
      this.incident_date = new Date()
      this.loaded = true
    }

    if (this.inModal) {
      this.makeModalLarge('animation-content')

    }

  }
  ,
  props: {
    canManage: {
      type: Boolean,
      default() {
        return false
      }
    }, canCreate: {
      type: Boolean,
      default() {
        return false
      }
    },
    canEdit: {
      type: Boolean,
      default() {
        return false
      }
    }, canDelete: {
      type: Boolean,
      default() {
        return false
      }
    },
    default_user: {
      type: Number,
      default() {
        return null
      }
    },
    incident:
        {
          type: Object,
          default
              () {
            return {}
          }
          ,
        }
    ,
    inModal: {
      type: Boolean,
      default
          () {
        return false
      }
    }
    ,
    edit: {
      type: Boolean,
      default
          () {
        return false
      }
    }
    ,
  }
}
</script>

